import HelmetTags from "../../../MainComponents/HelmetTags";
import { Col, Row } from "antd";

import { useEffect, useState } from "react";
import MathImage from "../../../assets/subjects/math.png";
import GeoghraphyImage from "../../../assets/subjects/geoghraphy.png";
import PhysicsImage from "../../../assets/subjects/physics.png";
import FalsfaImage from "../../../assets/subjects/falsfa.png";
import ChemistryImage from "../../../assets/subjects/chemistry.png";
import SpainImage from "../../../assets/subjects/spain.png";
import GermanImage from "../../../assets/subjects/german.png";
import FrenchImage from "../../../assets/subjects/french.png";
import BiologyImage from "../../../assets/subjects/biology.png";
import ItalyImage from "../../../assets/subjects/italy.png";
import GeologyImage from "../../../assets/subjects/geology.png";
import ArabicImage from "../../../assets/subjects/arabic.png";
import HistoryImage from "../../../assets/subjects/history.png";
import EnglishImage from "../../../assets/subjects/english.png";

import { Link } from "react-router-dom";

function StudentSubjects() {
  const [filtersubjects, SetFiltersubjects] = useState([]);
  const subjects = [
    { name: "Math", image: MathImage, link: "Math" },
    { name: "الجغرافيا", image: GeoghraphyImage, link: "الجغرافيا" },
    { name: "Physics", image: PhysicsImage, link: "Physics" },
    { name: "فلسفة وعلم منطق", image: FalsfaImage, link: "فلسفة وعلم نفس" },
    { name: "كيمياء", image: ChemistryImage, link: "كيمياء" },
    { name: "Science", image: ChemistryImage, link: "Science" },
    {
      name: "Integrated Science",
      image: ChemistryImage,
      link: "Integrated Science",
    },
    { name: "اللغة الاسبانية", image: SpainImage, link: "اللغة الاسبانية" },
    { name: "اللغة ألالمانية", image: GermanImage, link: "اللغة ألالمانية" },
    { name: "Chemistry", image: ChemistryImage, link: "Chemistry" },
    { name: "اللغة الفرنسية", image: FrenchImage, link: "اللغة الفرنسية" },
    { name: "أحياء", image: BiologyImage, link: "أحياء" },
    { name: "اللغة الايطالية", image: ItalyImage, link: "اللغة الايطالية" },
    { name: "Geology", image: GeologyImage, link: "Geology" },
    { name: "Math", image: MathImage, link: "Math" },
    { name: "اللغة العربية", image: ArabicImage, link: "اللغة العربية" },
    { name: "الجيولوجيا", image: GeologyImage, link: "الجيولوجيا" },
    { name: "فيزياء", image: PhysicsImage, link: "فيزياء" },
    { name: "تاريخ", image: HistoryImage, link: "تاريخ" },
    { name: "English", image: EnglishImage, link: "english" },
    { name: "Biology", image: BiologyImage, link: "Biology" },
  ];
  useEffect(() => {
    const userData = localStorage.getItem("userData");

    if (userData) {
      const parsedUserData = JSON.parse(userData);
      const userStage = parsedUserData?.student?.stage;

      // Filter subjects based on the user's stage
      const filteredSubjects = subjects.filter((subject) => {
        if (userStage === 1 && subject.name === "Science") return false; // Exclude Science for stage 1
        if (userStage === 33 && subject.name === "Science Integrated")
          return false; // Exclude Science Integrated for stage 33
        if (
          userStage !== 1 &&
          userStage !== 33 &&
          (subject.name === "Science" || subject.name === "Science Integrated")
        )
          return false; // Exclude both for other stages
        return true; // Include all other subjects
      });

      SetFiltersubjects(filteredSubjects);
    }
  }, []);
  return (
    <section className=" width  mb-32 h-full  dark:bg-dark dark:text-light">
      <HelmetTags title="All Subjects | PRODIGY"></HelmetTags>
      <div className="title-wrapper  mt-36">
        <h2
          className={`mb-20 text-center text-5xl font-bold transition-all duration-300 ease-linear `}
        >
          All Subjects
        </h2>
      </div>

      <Row gutter={[25, 25]} justify={"center"} align={"middle"}>
        {filtersubjects?.map((subject) => (
          <Col xs={24} md={12} lg={6} key={Math.random()}>
            <Link to={`/subjects/${subject.link}`}>
              <div className="flex flex-col items-center justify-center gap-6 rounded shadow-lg transition-all duration-700 hover:scale-110 py-10 px-5">
                <img src={subject.image} alt="" />
                <h3 className="font-bold text-3xl">{subject.name}</h3>
              </div>
            </Link>
          </Col>
        ))}
      </Row>
    </section>
  );
}

export default StudentSubjects;
