import {
  faFacebookF,
  faInstagram,
  faTelegram,
  faTiktok,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Footer() {
  return (
    <footer
      dir="ltr"
      id="contact"
      className=" relative z-40 bg-secondary text-light rounded-t-[50px]"
    >
      <div className="footer-container py-16 width footer-auto-fi flex items-center justify-between gap-14 md:flex-col md:items-center ">
        <div className="grid-col w-1/2 md:w-full">
          <h2 className="text-4xl font-bold md:text-center">PRODIGY</h2>
        </div>
        <div className="footer-socials flex w-1/2 flex-col items-end md:w-full md:items-center">
          <div className=" flex w-auto flex-col items-center gap-6 md:w-full md:items-center">
            <h2 className="text-3xl font-bold md:text-center">Social Media</h2>
            <ul className="flex w-full items-center justify-center gap-10  text-xl flex-wrap">
              <li className="group flex h-20 w-20 cursor-pointer items-center justify-center rounded-full border-2  border-light bg-light duration-300 hover:scale-110 hover:bg-transparent">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="text-2xl text-light/80  "
                 href="https://www.facebook.com/people/Prodigy-Online-Educational-Platform/61568875268772/"
                >
                  <FontAwesomeIcon
                    className="text-5xl text-secondary duration-300 group-hover:scale-110 group-hover:text-light"
                    icon={faFacebookF}
                  />
                </a>
              </li>

             
              <li className="group flex h-20 w-20 cursor-pointer items-center justify-center rounded-full border-2  border-light bg-light duration-300 hover:scale-110 hover:bg-transparent">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="text-2xl text-light/80  "
                  href="https://api.whatsapp.com/send?phone=+201067320862"
                >
                  <FontAwesomeIcon
                    className="text-5xl text-secondary duration-300 group-hover:scale-110 group-hover:text-light"
                    icon={faWhatsapp}
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <p className="text-center py-6  border-t-2 border-sedcondary">
        Created By{" "}
        <a
          className="font-bold "
          target="_blank"
          href="https://natlix.com"
          rel="noreferrer"
        >
          Natlix
        </a>
        | © {new Date().getFullYear()} All Rights Reserved.
      </p>
    </footer>
  );
}

export default Footer;
